import React from 'react';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo_desktop from '../images/trato-logo.svg';
import {
  faWhatsapp,
  faFacebook,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import logo from '../images/trato-logo.svg';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import emblema from '../images/newIndex/Image 2.svg';

export const WhatsMessage = (lang) => {
  const translation = {
    es: 'Hola, quiero empezar a gestionar mis contratos',
    en: 'Hello, I want to start managing my contracts',
    pt: 'Hello, I want to start managing my contracts',
  };
  const numberPhone = {
    es: '5215581079280',
    en: '+443330905249',
    pt: '3330905249',
  };
  return lang === 'en'
    ? `tel:${numberPhone[lang]}`
    : `https://wa.me/${numberPhone[lang]}?text=${encodeURIComponent(
        translation[lang]
      )}`;
};

const iconCall = {
  es: faWhatsapp,
  en: faPhoneAlt,
  pt: faPhoneAlt,
};

const toMail = {
  es: 'hola@trato.io',
  en: 'hello@trato.io',
};

function Footer({ locale }) {
  const data = useStaticQuery(socialQuery);
  const social = data.dataYaml;
  const { t } = useTranslation('home');

  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 mb-sm-3">
              <img
                alt="Trato Logo"
                src={logo_desktop}
                width={130.51}
                height={31.19}
              />
              <p className="text-muted mt-1">{t('footerSection.extra')}</p>
              <p className="text-muted">
                {t('footerSection.contact')}
                <OutboundLink
                  href={'mailto:' + toMail[locale]}
                  target="_blank"
                  className="mb-2 text-primary font-weight-bold"
                >
                  {' '}
                  {toMail[locale]}
                </OutboundLink>
              </p>
              <ul className="p-0">
                <li className="list-inline-item">
                  <a
                    href={WhatsMessage(locale)}
                    target="_blank"
                    className="lead text-dark"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={iconCall[locale]} />
                  </a>
                </li>
                <li className="list-inline-item ml-2">
                  <a
                    href="https://www.facebook.com/tratoapp"
                    target="_blank"
                    className="lead text-dark"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
                <li className="list-inline-item ml-2">
                  <a
                    href="https://www.linkedin.com/company/contratosapp/"
                    target="_blank"
                    className="lead text-dark"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
                <li className="list-inline-item ml-2">
                  <a
                    href="https://twitter.com/TratoApp"
                    target="_blank"
                    className="lead text-dark"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
                <li className="list-inline-item ml-2">
                  <a
                    href={'mailto:' + toMail[locale]}
                    className="lead text-dark"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-7 col-md-12 offset-lg-1">
              <div className="row">
                <div className="col-md-4 mb-sm-3">
                  <h5 className="text-muted font-weight-bold mb-2">
                    {t('footerSection.heading2')}
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-0">
                      <LocalizedLink to="/">
                        {t('footerSection.footerLink1')}
                      </LocalizedLink>
                    </li>
                    <li className="mb-0">{t('footerSection.footerLink2')}</li>
                    <li className="mb-0">{t('footerSection.footerLink3')}</li>
                    <li className="mb-0">
                      <LocalizedLink to="/blog">Blog</LocalizedLink>
                    </li>
                    <li className="mb-0">
                      <LocalizedLink to="/contacto">
                        {t('footerSection.footerLink5')}
                      </LocalizedLink>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 mb-sm-3">
                  <h5 className="text-muted font-weight-bold mb-2">
                    {t('footerSection.heading3')}
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-0">
                      <LocalizedLink to="/templates">
                        {t('footerSection.footerLink7')}
                      </LocalizedLink>
                    </li>
                    <li className="mb-0">
                      <LocalizedLink to="/trato-clm">Trato CLM</LocalizedLink>
                    </li>
                    <li className="mb-0">
                      <LocalizedLink to="/trato-starter">
                        Trato Starter
                      </LocalizedLink>
                    </li>
                    <li className="mb-0">
                      <LocalizedLink to="/legal-advisor">
                        Legal Advisor
                      </LocalizedLink>
                    </li>
                    {/* <li className="">Blockchain</li> */}
                  </ul>
                </div>
                <div className="col-md-4 mb-sm-3">
                  <h5 className="text-muted font-weight-bold mb-2">
                    {t('footerSection.heading4')}
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-0">{t('footerSection.footerLink9')}</li>
                    <li className="mb-2">
                      <LocalizedLink to="/faq">
                        {t('footerSection.footerLink10')}
                      </LocalizedLink>
                    </li>
                    {/* <li>
                      <a
                        href="https://www.pronetwork.mx/magazine/las-100-pro-reconoce-a-las-proximas-grandes-empresas-de-mexico/"
                        target="_blank"
                        className="lead text-dark"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={emblema}
                          alt=""
                          width={150}
                          height={90}
                          loading="lazy"
                        />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

const socialQuery = graphql`
  query SocialQuery {
    dataYaml {
      facebook
      email
      linkedin
      twitter
    }
  }
`;
