import React from 'react';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWhatsapp,
  faFacebook,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { WhatsMessage } from './Footer';

function SubFooter({ simpleSubFooter }) {
  const { t, i18n } = useTranslation('footer');
  const { locale } = useLocalization();

  return (
    <div id="sub-footer" className="container mt-5 sub-footer">
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-sm-3">
          {!simpleSubFooter && (
            <ul className="list-inline list-unstyled">
              <li className="list-inline-item">
                <span className="font-weight-bold">© 2023 Trato</span>
              </li>
              <li className="list-inline-item">
                <LocalizedLink to="/faq">{t('FAQ')}</LocalizedLink>
              </li>
              <li className="list-inline-item">
                <LocalizedLink to="/privacy">
                  {t('privacyPolicy')}
                </LocalizedLink>
              </li>
              <li className="list-inline-item">
                <LocalizedLink to="/terms">{t('terms')}</LocalizedLink>
              </li>
              <li className="list-inline-item">
                <LocalizedLink to="/policy">
                  {t(
                    'Política del Sistema de Gestión de Seguridad de la Información'
                  )}
                </LocalizedLink>
              </li>
            </ul>
          )}
          {simpleSubFooter && (
            <div className="d-flex justify-content-between">
              <div className="mt-1">
                <span className="font-weight-bold">&copy; 2023 Trato</span>
              </div>
              <div className="social-media">
                <ul className="p-0">
                  <li className="list-inline-item">
                    <a href="mailto:hola@trato.io" className="lead text-gray">
                      <FontAwesomeIcon icon={faEnvelope} />
                      <span className="small font-weight-bold ml-1">
                        soporte@trato.io
                      </span>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/tratoapp"
                      target="_blank"
                      className="lead text-gray"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>
                  <li className="list-inline-item ml-1">
                    <a
                      href={WhatsMessage(locale)}
                      target="_blank"
                      className="lead text-gray"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                  </li>
                  <li className="list-inline-item ml-1">
                    <a
                      href="https://www.linkedin.com/company/contratosapp/"
                      target="_blank"
                      className="lead text-gray"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li className="list-inline-item ml-1">
                    <a
                      href="https://twitter.com/TratoApp"
                      target="_blank"
                      className="lead text-gray"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-2 col-md-12" />
      </div>
    </div>
  );
}

SubFooter.propTypes = {
  simpleSubFooter: PropTypes.bool,
};

export default SubFooter;
